// Image
import "../css/pages/termsAndConditions.css";
import { Header, Footer } from "../components/index";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="termsAndConditionsPage PrivacyPolicyStyle">
        <div className="container paddingTopbottom">
          <h1>Privacy Policy</h1>

          <p className="dffectiveDate">
            <strong>Effective Date:</strong> 14th of January 2025
          </p>

          <p>
            At <strong>The Genuine</strong>, we value your privacy and are
            committed to protecting the personal information you provide to us.
            This Privacy Policy explains how we collect, use, store, and share
            your information. By using our website or services, you agree to the
            terms outlined below.
          </p>

          <hr />

          <strong>Information You Provide</strong>
          <p>
            You may provide us with personal information in various ways,
            including:
          </p>
          <ul>
            <li>Submitting a property inquiry.</li>
            <li>Contacting us via email, phone, or other channels.</li>
            <li>Completing a survey, review, or feedback form.</li>
            <li>Participating in promotions or competitions.</li>
          </ul>

          <p>
            We collect and store this information only with your consent when
            you agree to our terms and conditions.
          </p>

          <hr />

          <strong>Information We Collect Automatically </strong>
          <p>
            When you visit our website, we automatically collect certain
            metadata, including:
          </p>
          <ul>
            <li>Browser type and version. </li>
            <li>Device make and model. </li>
            <li>IP address.</li>
            <li>Referral domain (the site that directed you to us).</li>
            <li>Browser language settings.</li>
          </ul>

          <p>
            This information helps us improve your browsing experience and
            optimize our services.
          </p>

          <hr />

          <strong>How We Use Your Information</strong>
          <p>We may use your personal information to: </p>
          <ul>
            <li>
              Fulfill your requests, such as forwarding property inquiries to
              agents or developers.
            </li>
            <li>
              Provide updates and marketing communications about{" "}
              <i>The Genuine</i> services (with the option to unsubscribe).
            </li>
            <li>
              Enhance your experience on our website by tailoring content to
              your preferences.
            </li>
            <li>
              Deliver targeted advertising on our site and third-party
              platforms.
            </li>
            <li>
              Conduct internal business operations, including data analysis,
              troubleshooting, and research.
            </li>
          </ul>

          <hr />

          <strong>Sharing Your Personal Information </strong>
          <p>We may share your information in the following circumstances: </p>
          <ul>
            <li>
              <strong>With Agents and Developers:</strong> When you submit a
              property inquiry, we provide your name, email, and phone number to
              the relevant agent or developer. Once shared, their privacy policy
              will govern how they use your data.
            </li>
            <li>
              <strong>With Analytics Providers:</strong> For service improvement
              purposes, we may share metadata such as your IP address, pages
              visited, and browser type. No personal identifiers (e.g., name or
              email) are included.
            </li>
            <li>
              <strong>With Advertising Networks:</strong> To serve you relevant
              ads, we may share nonidentifiable metadata with advertising
              networks.
            </li>
            <li>
              <strong>For Legal or Professional Advice:</strong> If necessary,
              we may disclose your information to lawyers or advisors.
            </li>
            <li>
              <strong>In Compliance with Legal Obligations:</strong> If required
              by law or a government authority, we will share your personal
              information.
            </li>
          </ul>

          <hr />

          <strong>Storing Your Personal Information</strong>
          <p>
            Your information may be stored on secure servers located globally.
            By providing your information, you consent to its transfer, storage,
            and processing. We implement strict safeguards to protect your data
            but cannot guarantee complete security. Any information shared with
            us is at your own risk.
          </p>

          <p>
            If you have a password for accessing certain parts of our website,
            you are responsible for keeping it confidential. Please do not share
            it with anyone.
          </p>

          <hr />

          <strong>Data Retention and Deletion</strong>
          <p>
            We retain your personal information only as long as it is needed for
            the purposes for which it was collected. If you wish to have your
            information deleted from our servers, please contact us at{" "}
            <strong>info@thegenuine.ae</strong>
          </p>

          <hr />

          <strong>Cookies and Tracking Technologies</strong>
          <p>
            We use cookies to enhance your browsing experience. Cookies allow us
            to:
          </p>
          <ul>
            <li>
              Remember your preferences (e.g., currency settings or saved
              properties).
            </li>
            <li>
              Serve relevant advertisements using Google’s advertising network.
            </li>
            <li>Analyze user behavior to improve our services.</li>
          </ul>

          <p>You can manage cookie settings through your browser.</p>

          <hr />

          <strong>Links to Third-Party Websites</strong>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for their privacy policies, and this Privacy Policy does
            not apply to those sites. We recommend reviewing the privacy
            policies of any third-party websites before providing them with
            personal information.
          </p>

          <hr />

          <strong>Changes to This Policy</strong>
          <p>
            We may update this Privacy Policy periodically to reflect changes in
            our practices or legal requirements. Updates will be posted on this
            page.
          </p>

          <hr />

          <strong>Contact Us</strong>
          <p>
            If you have questions or concerns about this Privacy Policy or how
            your data is handled, please reach out to us:
          </p>
          <ul>
            <li>
              <strong>Email:</strong> info@thegenuine.ae
            </li>
            <li>
              <strong>Phone:</strong> +971 425 60 500
            </li>
            <li>
              <strong>Adress:</strong> Tamani Art Office , 1113, Dubai, UAE
            </li>
          </ul>

          <Link to={"/"} className="getGackToTheRegistrationPage">
            Go back to Home Page
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
